import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                     , name: 'home'                  , component: () => import( './pages/Login.vue'                      ) },
        { path: '/reset_password/:client/:token', name: 'reset_password'        , component: () => import( './pages/ResetClientPassword.vue'                      ) },
        { path: '/dashboard'            , name: 'dashboard'             , component: () => import( './pages/Dashboard.vue'                  ) },
        { path: '/categorii-drepturi'   , name: 'categorii-drepturi'    , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'     ) },
        { path: '/drepturi'             , name: 'drepturi'              , component: () => import ('./pages/drepturi/Drepturi_list.vue'                         ) },
        { path: '/grupuri-utilizatori'  , name: 'grupuri-utilizatori'   , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'   ) },
        { path: '/utilizatori'          , name: 'utilizatori'           , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                   ) },
        { path: '/clienti', name: 'clienti', component: () => import( './pages/clienti/Clienti_list.vue') },
        { path: '/parteneri', name: 'parteneri', component: () => import( './pages/parteneri/Parteneri_list.vue') },
        { path: '/carduri', name: 'carduri', component: () => import( './pages/carduri/Carduri_list.vue') },
        { path: '/tranzactii', name: 'tranzactii', component: () => import( './pages/tranzactii/Tranzactii_list.vue') },
        { path: '/tranzactii/:id_client', name: 'tranzactii_cu_client', component: () => import( './pages/tranzactii/Tranzactii_list.vue') },
        { path: '/comercianti', name: 'comercianti', component: () => import( './pages/comercianti/Comercianti_list.vue') },
        { path: '/pagini_comercianti', name: 'pagini_comercianti', component: () => import( './pages/pagini_comercianti/Pagini_comercianti_list.vue') },
        { path: '/pagini_comercianti/:id_comerciant', name: 'pagini_comercianti_cu_id_comerciant', component: () => import( './pages/pagini_comercianti/Pagini_comercianti_list.vue') },
        { path: '/pagini_parteneri', name: 'pagini_parteneri', component: () => import( './pages/pagini_parteneri/Pagini_parteneri_list.vue') },
        { path: '/generari_parteneri', name: 'generari_parteneri', component: () => import( './pages/generari_parteneri/Generari_parteneri_list.vue') },
        { path: '/coduri_parteneri', name: 'coduri_parteneri', component: () => import( './pages/coduri_parteneri/Coduri_parteneri_list.vue') },
        { path: '/notificari', name: 'notificari', component: () => import( './pages/notificari/Notificari_list.vue') },
        { path: '/tranzactii-neplatite', name: 'tranzactii_neplatite', component: () => import( './pages/tranzactii/Tranzactii_neplatite.vue') },
        { path: '/clienti-cec', name: 'clienti_cec', component: () => import( './pages/clienti-cec/ClientiCec.vue') },
        { path: '/plati', name: 'plati', component: () => import ('./pages/plati/Plati.vue')},
        { path: '/dubluri'          , name: 'dubluri'           , component: () => import ('./pages/dubluri/Dubluri_list.vue'                   ) },
        { path: '/clienti-figoPay', name: 'clienti-figoPay', component: () => import( './pages/clienti/ClientiFigo.vue') },
        {
            path: "/setari_generale",
            name: "setari_generale",
            component: () => import("./pages/setari_generale/Setari_generale.vue"),
          },

        
    ]
})
