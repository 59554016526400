import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/en";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BootstrapVue from "bootstrap-vue";
import moment from "moment";

import { Buffer } from "buffer";
globalThis.Buffer = Buffer;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.use(VueResource);
Vue.use(ElementUI, { locale });

Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();

Vue.http.options.root = settings.BASE_URL;

Vue.filter("momentformat", function (value) {
  return value;
});

Vue.filter("nicedateformat", function (value) {
  return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
});

import md5Hex from 'md5-hex';

Vue.prototype.$has_right = function (rightKey) {
  var ret = false;
  var drepturiDecoded = settings.get_drepturi();
  var numarUnic = (drepturiDecoded.length - 1) * (458 + 73 - 23);
  var caractereUnice = "nimic" + numarUnic;
  var verificareCrc = md5Hex(caractereUnice);
  if (drepturiDecoded.indexOf(verificareCrc) !== -1) {
    var indexDrept = drepturiDecoded.indexOf(rightKey);
    if (indexDrept !== -1) ret = true;
  }
  return ret;
};

Vue.prototype.$is_user_type = function (userType) {
  return settings.get_user_type() == userType;
};



Vue.prototype.$drepturi = function(parametru) {
  let ret = false;
  let list = settings.get_drepturi();
  for (var i = 0; i < list.length; i++) {
        console.log(list[i]);
        if (list[i] == parametru) ret = true;
      }
    return ret;
}

Vue.prototype.$drepturi = function(parametru) {
  var ret = false;
  var encryptedParametru = encrypt(parametru); // Encrypt the parameter

  var list = settings.get_drepturi();
  for (var i = 0; i < list.length; i++) {
    console.log(list[i]);
    if (list[i] === encryptedParametru) ret = true; // Compare with the encrypted value
  }

  return ret;
}






var currencyFormatter = require('currency-formatter');
Vue.filter( 'moneyformat', function(value){
    return currencyFormatter.format(value, {
        symbol: '',
        decimal: ',',
        thousand: '.',
        precision: 2,
        format: '%v'
      });
} );



new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
